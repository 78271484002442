import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fields", "selector"];

  toggle(event) {
    const $target = this.element.querySelector(`[data-secondary-service-types-target="fields"]`);

    if($target.classList.contains('hidden')) {
      $target.classList.remove("hidden");
      this.selectorTarget.innerHTML = "- Add one or more secondary products"
    } else {
      $target.classList.add("hidden");
      this.selectorTarget.innerHTML = "+ Add one or more secondary products"
    };
  }
}
