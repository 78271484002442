import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate", "endDateContainer"];

  setStartDate(event) {
    const startDateValue = event.target.value;
    if (!startDateValue) return;

    const startDate = new Date(startDateValue);
    const endDateInput = this.endDateTarget;
    const endDateFlatpickr = endDateInput._flatpickr;
    const endDate = endDateInput.value ? new Date(endDateInput.value) : null;

    if (!endDate || startDate > endDate) {
      endDateFlatpickr.setDate(startDate);
    }

    endDateFlatpickr.set("minDate", startDate);
    this.endDateContainerTarget.classList.remove("hidden");
  }
}
