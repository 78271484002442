import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "initial", "response"];
  static values = {
    copy: String,
  };

  copy() {
    navigator.clipboard.writeText(this.getCopyValue());

    this.toggleInitialTarget(false);

    if (this.hasResponseTarget) {
      this.responseTarget.classList.remove("hidden");
    } else {
      this.showTemporaryCopiedMessage();
    }

    this.resetAfterDelay();
  }

  getCopyValue() {
    return this.hasSourceTarget && this.sourceTarget.value
      ? this.sourceTarget.value
      : this.copyValue;
  }

  toggleInitialTarget(visible) {
    this.initialTarget.classList.toggle("hidden", !visible);
  }

  showTemporaryCopiedMessage() {
    const copiedMessage = document.createElement("span");
    copiedMessage.textContent = "Copied!";
    this.initialTarget.insertAdjacentElement("afterend", copiedMessage);
    this.temporaryMessage = copiedMessage;
  }

  resetAfterDelay() {
    setTimeout(() => {
      this.toggleInitialTarget(true);

      if (this.hasResponseTarget) {
        this.responseTarget.classList.add("hidden");
      }

      if (this.temporaryMessage) {
        this.temporaryMessage.remove();
        this.temporaryMessage = null;
      }
    }, 2000);
  }
}